import { createStore } from 'vuex'
import { Auth } from './auth'
import { getCookie, setCookie } from '@/utils/util'
import { Encrypt } from '@/utils/encrypt'
import { getViewBtuPermission } from "@/api/system/permission"
import { GetUserMenuList } from "@/api/system/permission"

export default createStore({
    state: {
        isLoading: false,
        currentSelect: {},

        context: {
            token: getCookie('access_token'),
        },

        avatarBg: '',
        tableSize: '', // 默认大小为空

        orderDrawer:false,

        theme: {
            name: '',
            color: '#202b3e',
            isBorder: false,
        },
     
        routes: [],
        userInfo:  {},  //Auth.getUserInfo() ||


        fileExcelType: ['.xls', '.xlsx'],
        fileImgType: ['.jpg', '.jpeg', '.png', '.gif'],
        fileAllType: ['.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.rar', '.zip', '.pdf', '.txt', '.xml', '.jpg', '.jpeg', '.png', '.gif', '.bmp'],

        // tab 数组
        editableTabsValue: Auth.getEditableTabsValue() || '/',
        editableTabs: Auth.getEditableTabs() || [{ title: '工作台', enTitle: 'Workbench', name: '/' }],

        //默认中文
        language: sessionStorage.getItem('locale') || "zh",
        // 系统名称
        systemName: sessionStorage.getItem('system_name') || '',
        menuCollapse: sessionStorage.getItem('menu:collapse') == 'true' || false
    },
    getters: {
        editableTabsValue: (state) => state.editableTabsValue,
        editableTabs: (state) => state.editableTabs,
        currentSelect: (state) => state.currentSelect,
        token: (state) => state.context.token,
        userInfo: (state) => state.userInfo,
        routes: (state) => state.routes,
        activityType: (state) => state.activityType,
        avatarBg: (state) => state.avatarBg,
        fileAllType: (state) => state.fileAllType,
        fileImgType: (state) => state.fileImgType,
        fileExcelType: (state) => state.fileExcelType,
        language: (state) => state.language,
        systemName: (state) => state.systemName,
        menuCollapse: (state) => state.menuCollapse,
    },
    mutations: {
        SHOW_LOADING: (state) => {
            state.isLoading = true
        },
        HIDE_LOADING: (state) => {
            state.isLoading = false
        },
        //退出登录时注销tab
        ACCOUNT_LOGOUT_FAILURE: (state) => {
            state.editableTabsValue = '/'
            state.editableTabs = [{ title: '工作台', enTitle: 'Workbench', name: '/' }]

            //其他代码
            Auth.removeEditableTabs()
            Auth.removeEditableTabsValue()
        },
        SAVE_CURRENT_SELECT: (state, val) => {
            state.currentSelect = val
        },
        SAVE_ROUTES: (state, data) => {
            state.routes = data
        },
        //设置当前tab数组
        ADD_TAB: (state, data) => {
            state.editableTabs = data
            Auth.setEditableTabs(data)
        },
        //设置当前tab
        SET_TAB_NAME: (state, data) => {
            state.editableTabsValue = data
            Auth.setEditableTabsValue(data)
        },
        //设置token
        SET_TOKEN: (state, data) => {
            state.context.token = data
        },
        // 设置用户信息
        SET_USERINFO: (state, data) => {
            state.userInfo = data
        },
        // 设置头像背景
        SET_AVATAR_BG: (state, data) => {
            state.avatarBg = data
        },
        // 切换主题
        TOGGLE_THEME: (state, data) => {
            if (data.name != undefined) state.theme.name = data.name
            if (data.color != undefined) state.theme.color = data.color
            if (data.isBorder != undefined) state.theme.isBorder = data.isBorder
        },
        //设置语言
        SET_LANGUAGE: (state, lang) =>{
            sessionStorage.setItem('locale', lang)
            state.language = lang
        },
        SET_MENU_COLLAPSE: (state, data) =>{
            sessionStorage.setItem('menu:collapse', data)
            state.menuCollapse = data
        },
        SET_MAIN_MENU: (state, data) => {
            state.mainRoutes = data
        },
        SET_COLLECT_MENU: (state, data) => {
            state.collectRoutes = data
        },
    },
    actions: {
        getPagesBtnPermission({}, title){
            return new Promise((resolve, reject) => {
                let obj = {
                    MenuName: title
                }

                getViewBtuPermission(obj)
                    .then(res => {
                        resolve(res.Data)
                    })
                    .catch(e => {})
            })
        },
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                queryUserInfo()
                    .then((res) => {
                        commit('SET_USERINFO', res.Data)
                        localStorage.setItem('user.cacheState', Encrypt.encode(JSON.stringify(res.Data)))
                        resolve()
                    })
                    .catch((e) => console.log(e))
            })
        },
        refreshUserToken({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                dispatch('getUserInfo').then(() => {
                    let obj = {
                        UserCode: getters.userInfo.UserCode,
                    }
                    refreshToken(obj)
                        .then((res) => {
                            setCookie('access_token', res.Data)
                            commit('SET_TOKEN', res.Data)
                            dispatch('getUserInfo')
                            resolve()
                        })
                        .catch((e) => console.log(e))
                })
            })
        },
        accountLogoutSubmit({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('ACCOUNT_LOGOUT_FAILURE')
                resolve()
            })
        },
        getMenu({ commit }) {
            return new Promise((resolve, reject) => {
                GetUserMenuList()
                    .then((res) => {
                        //console.log('get user menu')
                        //console.log(res.Data)
                        let menu = res.Data || []
                        for (let item of menu) {
                            item.name = item.meta.title
                            item.meta.name = item.meta.remark
                            item.meta.keepalive = true
                            if (item.children && item.children.length > 0) {
                                for (let son of item.children) {
                                    son.name =  son.meta.title
                                    son.meta.name = son.meta.remark
                                    son.meta.keepalive = true
                                }
                            }
                        }

                        // 初始化 工作台
                        if (menu.length == 0) {
                            menu = [{
                                path: '/',
                                name: 'Home',
                                meta: { title: 'Home', name: '工作台', enName: 'Workbench', keepalive: true, icon: "HomeFilled" },
                                component: () => import('@/views/Home.vue'),
                            }]
                        }
                        commit('SAVE_ROUTES', menu)
                        resolve(menu)
                    })
                    .catch((e) => {
                        console.log(e)
                        resolve([])
                    })
            })
        },
        /*
        getCollectMenu({ commit, getters }) {
            return new Promise((resolve, reject) => {
                let systemName = 'wash'
                let obj = {
                    SysCode: systemName
                }
                userFavMenuGetList(obj)
                    .then(res => {
                        commit('SET_COLLECT_MENU', res.Data)
                        resolve(res.Data)
                    })
                    .catch((e) => console.log(e))
            })
        },
        */
        getPagesBtnPermission({ }, title) {
            return new Promise((resolve, reject) => {
                let obj = {
                    MenuName: title
                }

                getViewBtuPermission(obj)
                    .then(res => {
                        resolve(res.Data)
                    })
                    .catch(e => { })
            })
        },
        getPageSearchList({}, code){
            return new Promise((resolve, reject) => {
                let obj = {
                    MenuCode: code
                }
                userSearchTermGetList(obj)
                    .then(res => {
                        resolve(res.Data)
                    })
                    .catch(e => { })
            })
        },
        getPageTableHeader({}, code){
            return new Promise((resolve, reject) => {
                let obj = {
                    MenuCode: code
                }
                userSearchColumnGet(obj)
                    .then(res => {
                        resolve(res.Data)
                    })
                    .catch(e => { })
            })
        },
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                queryUserInfo()
                    .then((res) => {
                        commit('SET_USERINFO', res.Data)
                        localStorage.setItem('user.cacheState', Encrypt.encode(JSON.stringify(res.Data)))
                        resolve()
                    })
                    .catch((e) => console.log(e))
            })
        },
        refreshUserToken({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                dispatch('getUserInfo').then(() => {
                    let obj = {
                        UserCode: getters.userInfo.UserCode,
                    }
                    refreshToken(obj)
                        .then((res) => {
                            setCookie('access_token', res.Data)
                            commit('SET_TOKEN', res.Data)
                            dispatch('getUserInfo')
                            resolve()
                        })
                        .catch((e) => console.log(e))
                })
            })
        },
        accountLogoutSubmit({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('ACCOUNT_LOGOUT_FAILURE')
                resolve()
            })
        },
        saveCurrentSelect: ({ commit }, val) => {
            commit('SAVE_CURRENT_SELECT', val)
        },
    },
    modules: {},
})
