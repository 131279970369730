<template>
    <div class="vue-content">
        <router-view><!--:key="$route.query" 此语句实现页面加载、Tab切换时强制刷新-->
            <template #default="{ Component, route }">
                <KeepAlive>
                    <component :is="Component" :key="route.meta.title" v-if="route.meta.keepalive" />
                </KeepAlive>
                <component :is="Component" :key="route.meta.title" v-if="!route.meta.keepalive" />
            </template>
        </router-view>
    </div>
</template>

<script>
export default {
    name: 'app-content'
}
</script>

<style lang="scss" scoped>
.vue-content {
    width: 100%;
    padding: 0px 15px 15px;
    // margin-top: 45px;
    //border: solid gray 1px;
    :deep(.el-card) {
        box-shadow: 0 10px 10px -10px rgba(0, 64, 128, 0.2);
        border: 1px solid transparent;
    }
}

.vue-content .vue-content {
    padding: 0;
}
</style>
